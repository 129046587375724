import React from 'react'
import { graphql } from 'gatsby'
import LocatableDetail from "../components/locatable-detail"

export default ({ data }) => {
  const deals = data.allDatoCmsDeal.edges.flatMap(e => e.node).filter(d => d.rides.find(a => a.id === data.locatable.id))
  return (
    <LocatableDetail settings={data.datoCmsWebsiteLayout} locatable={data.locatable} deals={deals} />
  )
}

export const query = graphql`
  query RideQuery($id: String!) {
    allDatoCmsDeal {
      edges {
        node {
          ...DealFields
          rides {
            id
          }
        }
      }
    }
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    locatable: datoCmsRide(id: { eq: $id }) {
      id
      title
      subtitle
      summary
      description
      heightRequirements      
      itemSponsors {
          ...Sponsor
      }
      model {
        apiKey
      }
      heroImage {
        url
        fluid(maxWidth: 2800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      events {
        ...EventFields
      }
      location {
        title
        building {
          id
          title
          slug
        }
      }
      subcategories {
        title
        heroTitleBlock {
          ...HeroTitleBlock
        }
        sponsors {
          ...Sponsor
        }
        landingPageDiscoverMore {
          ...DiscoverMore
        }
      }
      model {
        apiKey
      }
      pageHeader {
        ...HeroTitleBlock
      }
    }
  }
`
